import { start as startBackgroundImages } from './background-images'
import { start as startScrollHash } from './scroll-if-hash'
import { start as startScrollUp } from './scrollup'
import { start as startPswp } from './pswp'
//import { start as startPswpSwiperDemo } from './swiper-pswp-demo'
import { start as startNavbar } from './navbar'
import { start as startSwiperHero } from './swiper-hero'
import { start as startSwiperPopular } from './swiper-popular'
import { start as startCatalogMenu } from './catalog-menu/.default'

/**
 * все runtime скрипты ожидают загрузки страницы
 * @todo create window['RUNTIME] = new PlatformRuntime()
 * @todo переделать что можно Class/TypeScript
 */
document.addEventListener('DOMContentLoaded', () => {
  // navigation
  startNavbar()

  // common utils
  startBackgroundImages()
  startScrollHash({})
  startScrollUp('scroll-up-button')

  // galleries & swiper
  startPswp()
  //startPswpSwiperDemo();
  //startPswpSwiperDemo();
  startSwiperHero();
  startSwiperPopular();
  startCatalogMenu();
})
