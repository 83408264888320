/**
 * Функциональность сайта. Сохранение ссылок в
 * одном объекте позволяет лучше контролировать процессы.
 * А также управлять жизненным циклом приложений.
 *
 * примеры можно посмотреть в `src/platform/index.ts`
 */
import {
  FeedbackForm,
  FeedbackFormTrigger
} from '@/site/components/feedback'

import {
  OrderProductForm,
  OrderProductFormTrigger
} from '@/site/components/order-product'

import { PlatformBase } from "@/platform/platform"

 export class Site extends PlatformBase {
    constructor() {
      super()
      this.registerComponents([
      { name: 'feedback-form-contact', component: FeedbackForm},
      { name: 'order-product-form', component: OrderProductForm}
    ])

      this.mount()
    }

    mount(): void {
    this.collection['feedbackForm'] = this.mountComponent('#feedback-form-contact', FeedbackForm)
    this.collection['feedbackTriggers'] = this.mountAsTrigger('.js-feedback-trigger', 'FeedbackFormTrigger', FeedbackFormTrigger)
    this.collection['orderProductForm'] = this.mountComponent('#order-product-form', OrderProductForm)
    this.collection['orderProductFormTrigger'] = this.mountAsTrigger('.js-order-product-form-trigger', 'OrderProductFormTrigger', OrderProductFormTrigger)
    }
 }

/**
 * Функциональность сайта. Сохранение ссылок в
 * одном объекте позволяет лучше контролировать процессы.
 * А также управлять жизненным циклом приложений.
 *
 * примеры можно посмотреть в `src/platform/index.ts`


import { PlatformBase } from "@/platform/platform";

 export class Site extends PlatformBase {
    constructor() {
      super()
      this.mount()
    }

    mount(): void {
      console.log('mount site (empty)')
    }
 }
 */