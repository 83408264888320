import $ from 'jquery'

export function start (): void {
  // Переделать без использования jquery
  $(function () {  	
    var html = '.html-progect'
    var htmlClipped = 'is-clipped'
    var modalSelector = '.modal-container-nav'
    var modalActiveClass = 'is-active fadeIn'
    var modalButtonActive = '.button-menu-production'
    var modalButtonClose = '.close-modal-menu-production'
    
    $(modalButtonActive).click(function () {
      var $button = $(this);
      $(html).addClass(htmlClipped)
      $(modalSelector).addClass(modalActiveClass)
    })
    
    $(modalButtonClose).click(function () {
      $(html).removeClass(htmlClipped)
      $(modalSelector).removeClass(modalActiveClass)
    })
  })
  $(function () {
    $('.modal-container-nav .menu-first-name').on('click', function(){
     if ($(this).hasClass('is-active')) {
       $('.modal-container-nav .menu-first-name').removeClass('is-active');
      
     } else {
       $('.modal-container-nav .menu-first-name').removeClass('is-active');
       $(this).addClass('is-active');
      }
    })
  })
}