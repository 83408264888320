import { store } from '@/store'
import { mapGetters } from 'vuex'

/**
 * Специальный миксин для форм
 *
 * включает в себя основные функции
 * с параметры для работы формы.
 *
 * - статусы состояния (методы, состояния, помощники)
 * - универсальный метод отправки на сервер
 *
 * Чтобы использовать необходимо строго соблюдать
 * структуру шаблона. Настройки формы и валидации (vee-validate)
 *
 * Примеры использования
 *
 * - FeedbackForm (обратная связь)
 * - ReviewQuestionForm (отзыв)
 *
 * Рекомендуется к использованию со всеми формами проекта.
 *
 * @todo rewrite to typescript
 * @todo see https://github.com/vuetifyjs/vuetify/tree/master/packages/vuetify/src/mixins
 *
 */
const Status = {
  store,
  props: {
    /**
     }
     * если форма открывается в
     * модальном окне, через trigger
     * необходимо указать это в параметрах
     * модального окна
     *
     * При работе с триггером меняется
     * логика отображения статусов и логика
     * сброса состояния формы
     */
    trigger: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      /**
       * @todo реализовать расширенный вывод ошибок
       */
      showExtendedError: false,
      /**
       * локальные ошибки, используется
       * при получении ошибок с сервера
       *
       * @todo rename (конфликтует с VeeValidate Observer)
       */
      errors: [],
      /**
       * стандартные поля формы обратной связи
       * добавить и переопределить поля можно
       * в конкретном компоненте, где
       * используется миксин
       */
      formData: {
        name: null,
        email: null,
        phone: null,
        message: null,
        file: null
      },
      /**
       * статус состояния компонента
       */
      status: {
        /**
         * начальное состояние
         */
        pristine: true,
        /**
         * успешно отправлено
         */
        success: false,
        /**
         * ошибки при отправке на сервер
         */
        error: false,
        /**
         * пользователь закрыл сообщение
         * об ошибках, после неудачной
         * отправки на сервер
         */
        dirty: false
      }
    }
  },
  computed: {
    ...mapGetters(['isLock']),
    hasErrors() {
      return this.errors.length > 0
    },
    isSuccess() {
      return this.status.success === true
    },
    isPristine() {
      return this.status.pristine === true
    },
    isNotSuccess() {
      return this.status.success !== true
    }
  },
  methods: {
    /**
     * отправка запроса на сервер
     */
    submit() {
      /**
       * перед отправкой необходимо
       * очистить ошибки и состояния
       */
      this.status.dirty = true
      this.status.success = false
      this.status.error = false
      this.status.pristine = false
      this.errors.splice(0, this.errors.length + 1)

      /**
       * предварительная принудительная
       * проверка данных формы
       */
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }

        const form = new FormData(this.$refs.form)

        this.$store.commit('lock')

        /**
         * собственно отправка запроса на сервер
         * необходимо убедиться что идентификатор формы
         * указан верно
         */
        this.$http.post(`/platform/api/forms/${this.formId}/submit/`, form, {})
          .then(() => {
            this.$store.commit('unlock')
            this.status.success = true
            this.resetForm()
          })
          .catch(({body}) => {
            /**
             * @todo парсер ошибок
             */
            this.$store.commit('unlock')
            this.errors.push(body.errors)
            this.status.error = true
          })
      })
    },
    /**
     * полный сброс
     * состояния компонента и формы
     */
    resetState() {
      this.resetStatus()
      this.resetForm()
    },
    /**
     * полный сброс всех заполненных полей
     * и состояния проверки данных (валидации)
     *
     * Можно использовать кнопку reset,
     * VeeValidate обрабатывает ее нажатие
     * по-умолчанию.
     *
     * Данные сброс применяется программно
     * при успешной отправке формы
     *
     */
    resetForm() {
      /**
       * reset all keys
       */
      for (const field in this.formData) {
        if (Object.prototype.hasOwnProperty.call(this.formData, field)) {
          this.formData[field] = null
        }
      }

      /**
       * необходимо добавить ref="observer" к
       * корневому validation-observer
       */
      this.$nextTick(() => {
        this.$refs.observer.reset();
      })
    },
    /**
     * сброс локального статуса компонента
     * на начальное состояние
     */
    resetStatus() {
      this.status.success = false
      this.status.error = false
      this.status.dirty = false
      this.status.pristine = true
      // clean errors
      // this.errors.splice(0, this.errors.length + 1)
    },
    /**
     * работает только с компонентами Buefy
     * или им подобными
     */
    onCloseNotification() {
      /**
       * при открытии формы внутри
       * модального окна закрытие при
       * статусе 'success' должно
       * закрывать модальное окно
       *
       * для указания на работу
       * в модальном окне используется
       * параметр trigger
       *
       * логика закрытия и открытия, обмена
       * событиями между компонентами имеет
       * первостепенное значение.
       *
       * При неправильном применении легко получить
       * ошибки типа `CallStack Exceeded`
       */
      if (this.isSuccess) {
        /**
         * свойство trigger должно
         * передаваться/устанавливаться
         * при открытии/инициализации
         * модального окна (см. FeedbackFormTrigger)
         */
        if (this.trigger) {
          /**
           * событие закрытия модального окна
           * будет перехвачено в обработано (по-умолчанию)
           */
          this.$emit('close')
          this.$destroy()
        } else {
          this.resetStatus()
        }
      } else {
        this.status.pristine = false
        this.status.dirty = true
      }
    }
  }
}

export default Status
