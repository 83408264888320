import Swiper, {Autoplay, Pagination} from 'swiper'

/**
 * начиная с версии 5.x различные модули необходимо
 * импортировать и подключать отдельно, это касается и
 * javascript и стилей, это рекомендованный подход
 */
Swiper.use([Autoplay, Pagination])

/**
 * стили библиотеки подключать всегда в общий поток стилей
 * main.sass/libs, иначе они будут выделены
 * в отдельный "чанк" и не будут подключены
 * в стили шаблона.
 */

//require('./style.scss')

export function start(selector = '.js-popular-slider'): Swiper|undefined {
  if (!document.querySelector(selector)) return
  /**
   * @see https://swiperjs.com/api/
   */
  return new Swiper(selector, {
    watchSlidesProgress: true,
    slidesPerView: 1,
    spaceBetween: 2,
    breakpoints: {
      1216: {
        slidesPerView: 4,
        spaceBetween: 10
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 10
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10
      }
    },
    loop: false,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true
    },
    scrollbar: {
      el: '.swiper-scrollbar'
    }
  })
}
