<template>
  <a class="order-product-form-trigger button is-info" @click.stop.prevent="openForm()" :class="customClass">
    <slot name="title">
      <span class="button-title">{{title}}</span>
    </slot>
  </a>
</template>
<script>
import Vue from 'vue'
import OrderProductForm from "@/site/components/order-product/OrderProductForm";
import FormTriggerMixin from "@/mixins/form-trigger-mixin";

export default Vue.extend({
  mixins: [FormTriggerMixin],
  props: {
    /**
     * Битрикс ID
     */
    productId: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * Адрес страницы продукта
     * на сайте
     */
    productUrl: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * Название продукта
     */
    productName: {
      type: String,
      required: true,
      default: ''
    },
  },
  methods: {
    openForm() {
      this.$buefy.modal.open({
        component: OrderProductForm,
        width: 640,
        customClass: 'form-in-modal',
        canCancel: ['x'],
        /**
         * опция используется для определения
         * что форма открыта в модальном окне,
         * это не стандартная опция она добавлена
         * специально в форму, и отслеживается
         * при закрытии уведомления об успешной
         * отправке
         */
        props: {
          trigger: true,
          /**
           * "пробрасываем" параметры триггера
           * в форму
           */
          productId: this.productId,
          productUrl: this.productUrl,
          productName: this.productName
        }
      })
    }
  }
})
</script>
