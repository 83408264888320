<template>
  <div class="platform-order-product-form section">
    <validation-observer ref="observer" v-slot="{invalid, handleSubmit}">
      <div class="form-header" ref="header" v-if="isNotSuccess">
        <p class="title is-4" ref="title">
          Заказать товар
        </p>
      </div>
      <hr class="has-background-grey">  

      <div class="form-messages" ref="messages">
        <b-notification type="is-danger" @close="onCloseNotification" has-icon v-model="status.error">
          <p><strong>Ошибка! Не удалось отправить сообщение.</strong></p>
          <p>Попробуйте снова, если ошибка повторяется, обновите страницу.</p>

          <template v-if="showExtendedError">
            <p>Описание ошибки:</p>
            <span v-for="(item, index) in errors" :key="index">
              <strong>{{item}}</strong>
            </span>
          </template>
        </b-notification>

        <b-notification type="is-success" @close="onCloseNotification" has-icon v-model="status.success">
          <p><strong>Спасибо! Ваше сообщение отправлено.</strong></p>
          <p>Постараемся рассмотреть ваше обращение как можно быстрее.</p>
        </b-notification>
      </div>

      <div class="form-wrapper" v-if="isNotSuccess" ref="wrapper">
        <form @submit.prevent.stop="handleSubmit(submit)" class="form" ref="form" enctype="multipart/form-data">
          <input type="hidden" name="WEB_FORM_ID" :value="formId">
          <input type="hidden" name="form_text_59" value="да">

          <div class="columns">
            <div class="column is-12">
              <!-- Наименование товара -->
              <!--input name="form_text_57" :value="productName" class="input is-small"-->
              
              <validation-provider>
                <b-field label="Наименование товара">
                  <b-input name="form_text_57" :disabled="isLock" :value="productName"></b-input>
                </b-field>
              </validation-provider>
            </div>
            <div class="column is-hidden">
              <!-- Продукт ID -->
              <input name="form_text_60" :value="productId" class="input is-small">
            </div>
            <div class="column is-hidden">
              <!-- Ссылка на товар -->
              <input name="form_text_58" :value="productUrl" class="input is-small">
            </div>
          </div>
          <br>
          <p>Контакные данные</p>
          <div class="divider mt-1 mb-4" style="margin-left: 0;"></div>
          
          <div class="columns is-multiline">
            <div class="column is-12">
              <validation-provider v-slot="v" rules="required">
                <b-field label="Имя и фамилия *" :type="{'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
                  <b-input name="form_text_55" :disabled="isLock" v-model="formData.name" placeholder="Имя и фамилия"></b-input>
                </b-field>
              </validation-provider>
            </div>

            <div class="column is-6">
              <validation-provider v-slot="v" rules="required" name="Телефон" slim>
                <b-field label="Телефон *" message="Ваш контактный телефон" :type="{ 'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
                  <b-input name="form_text_54" placeholder="+7 000 000-00-00" v-model="formData.phone"></b-input>
                </b-field>
                <p class="has-text-danger" v-if="v.invalid && v.dirty">
                  {{v.errors[0]}}
                </p>
              </validation-provider>
            </div>

            <div class="column is-6">
              <validation-provider v-slot="v" rules="required|email" name="Email" slim>
                <b-field label="Email *" message="Ваш адрес электронной почты" :type="{ 'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
                  <b-input name="form_email_53" placeholder="email@email.ru" v-model="formData.email"></b-input>
                </b-field>
                <p class="has-text-danger" v-if="v.invalid && v.dirty">
                  {{v.errors[0]}}
                </p>
              </validation-provider>
            </div>

            <div class="column is-12">
              <validation-provider v-slot="v" rules="required|max:1000" slim name="Сообщение">
                <b-field label="Сообщение *" message="Сообщение или ваши вопросы" :type="{ 'is-danger': v.invalid && v.dirty, 'is-success': v.valid }">
                  <b-input name="form_textarea_52" placeholder="сообщение или вопрос..." type="textarea" v-model="formData.message"></b-input>
                </b-field>
                <p class="has-text-danger" v-if="v.invalid && v.dirty">
                  {{v.errors[0]}}
                </p>
              </validation-provider>
            </div>

            <div class="column is-12">
              <b-field>
                <button class="button is-primary" type="submit" :disabled="isLock || invalid" :class="{ 'is-loading': isLock }">отправить</button>
              </b-field>
            </div>
          </div>

          <p>
            Заполните и отправьте свои контактные данные, <strong>мы Вам перезвоним</strong>.
          </p>
          <p class="help">
            Отправляя форму вы соглашаетесь на обработку ваших персональных данных
            в соответствие с <a href="/agreement/" target="_blank">пользовательским соглашением сайта</a>. Передаваемая контактная информация используется исключительно для связи с Вами.
          </p>

        </form>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue'
import FormCommonMixin from '@/mixins/form-common-mixin'

export default Vue.extend({
  props: {
    /**
     * Битрикс ID
     */
    productId: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * Адрес страницы продукта
     * на сайте
     */
    productUrl: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * Название продукта
     */
    productName: {
      type: String,
      required: true,
      default: ''
    },
  },
  mixins: [FormCommonMixin],
  data () {
    return {
      formId: 6
    }
  }
})
</script>
<style lang="sass" scoped>
@import "../../../sass/_helpers"
.platform-order-product-form.section
  padding: 1.4rem
.platform-order-product-form
  background: #fff
  border-radius: 6px
p.title
  margin-bottom: 1rem
.is-success
  .title
    margin-bottom: 0
  p.success-message
    margin-bottom: 1rem
</style>
