import {TweenMax, gsap} from 'gsap'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

interface ScrollHashOptions {
  /** селектор ссылок, по умолчанию: `a[href^="#"]` */
  selector?: string;
  /** скорость анимации */
  speed?: number;
}

export function start(opts: ScrollHashOptions): ScrollHash {
  return new ScrollHash(opts.selector)
}

/**
 * Подкрутка к элементу на странице.
 * Инициализируются все ссылки на странице по селектору `a[href^="#"]`.
 *
 * ```html
 * <!-- trigger -->
 * <a href="#section-to-scroll">go to section</a>
 *
 * <!-- target -->
 * <section id="section-to-scroll">
 *  <p>section</p>
 * </section>
 * ```
 */
export class ScrollHash {
  /* eslint no-undef: 0 */
  elements: NodeListOf<HTMLLinkElement>
  /** отступ сверху от целевого элемента */
  offset: number
  /** сохранять историю навигации в браузере */
  usePushState: boolean
  /**скорость анимации */
  selector: string
  /** ошибки */
  errors: [] = []

  constructor(selector: string|undefined) {
    this.offset = 50
    this.usePushState = false
    this.selector = selector || 'a[href^="#"]'
    this.elements = document.querySelectorAll(this.selector)
    this.elements.forEach((el) => this.scrollHandler(el))
  }

  scrollHandler(el: HTMLLinkElement): void {
    el.addEventListener('click', (e:MouseEvent) => {
      e.preventDefault()

      const href: string|null = el.getAttribute('href')

      if (!href) return
      if (href === '#') return
      if (href.length < 2) return

      TweenMax.to(window, 1, {
        onComplete: () => {
          if (window.history && 'pushState' in history && this.usePushState) {
            history.pushState({}, document.title, window.location.pathname + href)
            return false
          }
        },
        scrollTo: {
          y: href,
          offsetY: this.offset
        }
      })

      return false
    })
  }
}
